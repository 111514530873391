import React from "react"

import MainLayout from "../layouts"

const NotFoundPage = () => (
  <MainLayout title="404: Not found">
    <div className="my-5 p-5">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </MainLayout>
)

export default NotFoundPage
